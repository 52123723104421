$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;
@import './inputs.scss';

.bold-header {
  font-family: "Space Mono Bold", sans-serif;
}

.definition-link {
  font-family: "Helvetica Medium", sans-serif;
  font-size: 16px;
  color: $black;
}

.definition-link:hover {
  color: $black;
}

.header-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 2rem;
    margin: 3rem auto 2rem auto;
    @media only screen and (min-width: $md){
      padding-left: 15px;
      padding-right: 15px;
      margin: 6rem auto 2rem auto;
    }
    @media only screen and (min-width: $lg){
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

.content-container {
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
    padding: 0 15px;
    @media only screen and (min-width: $md){
      padding: 0 15px;
  }
    @media only screen and (min-width: $lg){
      padding: 0 6rem;
    }
}

::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: none;
}

::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
	background-color: none;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    opacity: 0.6;
	background-color: $black;
}

.mono-type {
  font-family: "Space Mono", sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.mono-type__geometry {
  margin-bottom: 0.5rem;
}

.mono-type__dimensions {
  margin-bottom: 1.5rem;
}

.section-title {
  font-family: "Helvetica Bold", sans-serif;
  font-size: 24px;
  margin-bottom: 2rem;
  text-decoration: underline;
  @media only screen and (min-width: $md){
    margin-bottom: 2.5rem;
  }
}

.bottom-container {
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  padding: 0 15px;
  margin-top: 6rem;
  @media only screen and (min-width: $md){
    padding: 0 15px;
    margin-top: 4rem;
}
  @media only screen and (min-width: $lg){
    padding: 0 6rem;
  }
}

.title {
    font-family: "Helvetica Bold", sans-serif;
    font-size: 36px;
    color: $black;
    padding-bottom: 0.5rem;
    display: table-cell;
    border-bottom: 3px solid #212529;
    @media only screen and (min-width: $md){
      font-size: 48px;
      border-bottom: 4px solid #212529;
    }
    @media only screen and (min-width: $lg){
      font-size: 72px;
      border-bottom: 6px solid #212529;
    }
}

.title:after {
    width: 100%;
}

.subtitle {
    font-family: "Helvetica Regular", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-left: -15px;
    margin-top: 1rem;
    margin-bottom: 0;
    @media only screen and (min-width: $md){
      font-size: 16px;
    }
    @media only screen and (min-width: $lg){
      font-size: 18px;
    }
}

.letters, .terms {
    font-family: "Helvetica Medium", sans-serif;
    color: $black;
    @media only screen and (max-width: $md){
      margin: 0 0.5rem 1rem 0.5rem;
      white-space: nowrap;
    }
    @media only screen and (min-width: $md){
      font-size: 16px;
    }
    @media only screen and (min-width: $lg){
      font-size: 32px;
    }
}

.footer-container {
  border-top: 1px solid rgba(0,0,0,0.75);
  margin-top: 8rem;
  padding: 3rem 15px;
  @media only screen and (min-width: $md){
    font-size: 16px;
    padding-top: 3rem;
  }
  @media only screen and (min-width: $lg){
    font-size: 18px;
    padding: 3rem 6rem;
  }
}

.footer-title {
  font-family: "Helvetica Bold", sans-serif;
  font-size: 24px;
}

.footer-title--link, .footer-title--link, .footer-title--link:hover {
  color: $black;
  text-decoration: none;
}

.footer-initials {
  font-family: "Space Mono", sans-serif;
  font-size: 14px;
  margin-bottom: 1rem;
}

.footer-link {
  font-family: "Helvetica Medium", sans-serif;
  text-decoration: none;
  color: $black;
    @media only screen and (min-width: $md){
      font-size: 16px;
    }
    @media only screen and (min-width: $lg){
      font-size: 18px;
    }
}

.footer-link:hover {
  color: $black;
}

.footer-link2 {
  font-family: "Helvetica Medium - original", sans-serif;
  text-decoration: none;
  color: $black;
    @media only screen and (min-width: $md){
      font-size: 16px;
    }
    @media only screen and (min-width: $lg){
      font-size: 18px;
    }
}

.footer-link2:hover {
  color: $black;
}

.initials-about {
  margin-top: 3rem;
  @media only screen and (min-width: $md){
    margin-top: 0;
  }
}

.footer-p {
  font-family: "Helvetica Regular", sans-serif;
}

.results {
  font-family: "Helvetica Medium", sans-serif;
  font-size: 24px;
}

.results-gray {
  font-family: "Helvetica Light", sans-serif;
}

.results-volume {
  text-decoration: underline;
}

.results-container {
  padding-top: 4rem;
}

.results-bottom {
  border-top: 1px solid $black;
}

.results-value {
  font-family: "Helvetica Bold", sans-serif;
  text-decoration: underline;
}

.results-value__plain {
  font-family: "Helvetica Bold", sans-serif;
}

.know-list {
  margin: 0;
}

.know-list__wrapper {
  margin-top: rem;
}

.enlarged-img {
  width: 50%;
  margin: 0 auto;
}

.privacy-header {
  margin-top: 2rem;
}