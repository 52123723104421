$input-width: 225px;
$input-height: 64px;
$font24: 24px;
$black: #212529;

html {
    // scroll-behavior: smooth;
}

label {
    position: absolute;
    font-family: 'Helvetica Medium', sans-serif;
    font-size: 14px;
    color: #424242;
}

input, label, select {
    display: block;
    padding: 0.3rem 0.5rem;
}

select, input {
    height: $input-height;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 1rem 0 !important;
    padding-top: 1.25rem;
    width: $input-width;
}

select, input{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select:hover {
    cursor: pointer;
}


select:focus, input:focus {
    border: 2px solid $black;
    border-radius: 4px;
}

.input {
    margin: 1rem 0;
    font-family: 'Helvetica', sans-serif;
    font-size: $font24;
}

.calculate-btn, .calculate-btn:disabled {
    margin: 1rem 0 3rem 0;
    font-family: 'Helvetica Bold', sans-serif;
    font-size: $font24;
    width: $input-width;
    height: $input-height;
    background-color: white;
    border-radius: 6px;
    outline: none;
    box-shadow: 0 0 0 4px $black;
    border: 1px solid $black;
    color: $black;
}

.calculate-btn:hover {
    background-color: $black;
    color: white;
    cursor: pointer;
}

.input-units {
    margin-top: calc(31.6px / 2);
}
