.category-button {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  min-width: 220px;
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  margin-right: .5rem;
  border-radius: 24px;
  background-color: white;
  margin-top: 1rem;
}

.category-button:hover {
  cursor: pointer;
}

.category-button:focus {
  outline: none;
}

.category-button__inactive, .category-button__inactive:hover {
  border: 1px solid #000;
}

.category-button__inactive:hover {
  box-shadow: 0 0 0 6px #000;
}

.category-button__active, .category-button__active:hover {
  border: 1px solid #5ECB5B;
  box-shadow: 0 0 0 6px #5ECB5B;
}

.category-button__title {
  font-family: 'Helvetica Bold', sans-serif;
  font-size: 16px;
  margin-bottom: 1rem;
  width: 100%;
}

.category-image {
  max-width: 100%;
  height: 150px;
  margin: auto;
}

.categories-container {
  overflow-x: scroll;
  padding: 0 1rem 1rem 1rem;
}

@media only screen and (min-width: 720px){
  .category-button {
    width: 275px;
    height: 275px;
    margin-right: 1rem;
  }
  .category-image {
    height: 175px;
  }
  .category-button__title {
    font-size: 24px;
  }
  .categories-container {
    flex-wrap: wrap;
    overflow: visible;
  }
}
@media only screen and (min-width: 960px){
  .categories-container {
    padding: 0;
  }
}