
@font-face {
  font-family: "Helvetica Light";
  src: url("../fonts/AOTFShinGoProLight.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Regular";
  src: url("../fonts/AOTFShinGoProRegular.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Medium";
  src: url("../fonts/AOTFShinGoProMedium.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Medium - original";
  src: url("../fonts/HelveticaNeueMedium.woff2") format("woff2");
}

@font-face {
  font-family: "Helvetica Bold";
  src: url("../fonts/AOTFShinGoProBold.woff") format("woff");
}

@font-face {
  font-family: "Space Mono";
  src: url("../fonts/SpaceMono-Regular.woff2") format("woff");
}

@font-face {
  font-family: "Space Mono Bold";
  src: url("../fonts/SpaceMono-Bold.woff2") format("woff");
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaNeueMedium.woff2") format("woff2");
}